import React from 'react'

function OldQuestions() {
  return (
    <div>
      <h3> Welcome to old questions bank </h3>
      </div>
  )
}

export default OldQuestions
