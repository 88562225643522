import React from 'react';
import ContactForm from '../components/ContactForm';

function ContactUs() {
  return (
    <div>
        <h3> Please fill the form to contact us!</h3>
      <ContactForm/>
    </div>
  )
}

export default ContactUs
